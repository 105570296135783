
import { api } from "@/api/api";
import { ApiArticleDtoType, ApiCourseEconomyBaseDataDto } from "@/api/generated/Api";
import Files from "@/components/course/new/steps/economy/Files.vue";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import { validateNotEmpty, validateKasId } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { Organization } from "@/store/modules/memberOrganizations.store/interfaces/Organization.interface";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import Vue from "vue";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { ClientAccountInvoiceTypes } from "@/shared/constants/ClientAccountInvoiceTypes";

export default defineComponent({
  name: "NewCourseEconomyStep",

  components: { FormElement, Files },
  props: {
    curriculumId: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const availableArticles = ref<ApiArticleDtoType[]>([]);
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    if (courseValues.value.economy === undefined) {
      Vue.set(courseValues.value, "economy", {
        ClientAccountInvoiceTypes: "None",
      });
    }
    const economyDefaultOptions = ref<ApiCourseEconomyBaseDataDto>({});
    const departmentId = ref(1);
    const showInvoiceTypeSelect = ref(false);

    const onStepEnter = () => {
      loadAvailableArticles(props.curriculumId);
      handleGrantChange();
    };
    onMounted(() => {
      loadAvailableArticles(props.curriculumId);
      handleGrantChange();
    });

    const loadAvailableArticles = (curriculumId: number) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const [curriculumResponse, economyDefaultOptionsResponse, employeeSelfResponse] = await Promise.all([
          api.curriculum.getCurriculumAsync(curriculumId),
          api.economy.getCourseEconomyBaseAsync(),
          api.user.getCurrentEmployeeAsync(),
        ]);

        if (!curriculumResponse.data.teachingMethod || !economyDefaultOptionsResponse.data.articles) {
          openNotification(store, NotificationItemType.Warning, "Det skjedde en feil under henting av artikler");
          return;
        }

        economyDefaultOptions.value = economyDefaultOptionsResponse.data;
        availableArticles.value = economyDefaultOptionsResponse.data.articles
          .filter((article) => article.teachingMethod === curriculumResponse.data.teachingMethod?.toLowerCase())
          .filter((x) => {
            if (x.id) {
              // Fix to remove 40000 - 40099 series articles from list. This is a hasty approach approved by customer as time was of the essence.
              // This will probably/eventually fail as the article codes have been change once before, most likely will change again.
              // Sorry future developer.
              return !(+x.id >= 40000 && +x.id <= 40099);
            }
          });

        departmentId.value = employeeSelfResponse.data.currentOrganizationId;
      });
    };

    runStoreAction(
      store,
      StoreModules.MemberOrganizations,
      StoreActions.MemberOrganizationsActions.FetchMemberOrganizations
    );

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        emitChanges();
        return true;
      }
      return false;
    };

    const emitChanges = () => {
      context.emit("input", deepCloneObject(courseValues.value));
    };

    const handleGrantChange = () => {
      showInvoiceTypeSelect.value = false;

      // if the organizerOrganizationId is and lo accounting department set
      //  the client account invoice type to AccountingDepartment
      const grantId = courseValues.value.economy?.grantId;
      if (grantId?.toString() === "25" || grantId?.toString() === "20") {
        const accountingDepartmentIds =
          economyDefaultOptions.value.accountingDepartments &&
          economyDefaultOptions.value.accountingDepartments.map((x) => x.id);
        if (accountingDepartmentIds && accountingDepartmentIds.includes(courseValues.value.organizerOrganizationId)) {
          courseValues.value.clientAccountInvoiceType = "AccountingDepartment";
          showInvoiceTypeSelect.value = false;
        } else {
          showInvoiceTypeSelect.value = true;
        }
      } else {
        courseValues.value.clientAccountInvoiceType = "None";
      }
      emitChanges();
      return showInvoiceTypeSelect;
    };

    return {
      courseValues,
      departmentId,
      validateNotEmpty,
      validateKasId,
      validate,
      onStepEnter,
      economyDefaultOptions,
      availableArticles,
      organizationList: computed<Organization[]>(() => store.state.memberOrganizations.organizations),
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      isCourseDone: props.value.status === CourseStatus.Closed,
      emitChanges,
      handleGrantChange,
      showInvoiceTypeSelect,
      ClientAccountInvoiceTypes,
    };
  },
});
