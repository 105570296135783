import { ClientAccountInvoiceType } from "../enums/ClientAccountInvoiceType.enum";

export type ClientAccountInvoiceTypes = {
  name: string;
  value: string;
};
export const ClientAccountInvoiceTypes: ClientAccountInvoiceTypes[] = [
  {
    name: "Lokal",
    value: ClientAccountInvoiceType.Local.toString(),
  },
  {
    name: "Sentral",
    value: ClientAccountInvoiceType.Central.toString(),
  },
];
